var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableMolecule', [_c('HeaderMolecule', {
    staticClass: "header",
    attrs: {
      "title": _vm.title,
      "buttonText": _vm.buttonText,
      "mode": _vm.mode,
      "to": _vm.to,
      "showBackButton": _vm.showBackButton,
      "count": _vm.tableData.length,
      "goBackRoute": _vm.goBackRoute,
      "disabledAction": _vm.disabledAction,
      "enableSearch": _vm.enableSearch,
      "searchQuery": _vm.searchQuery,
      "isLoading": _vm.isLoading
    },
    on: {
      "action": function action($event) {
        return _vm.$emit('action', $event);
      },
      "search": function search($event) {
        return _vm.$emit('search', $event);
      }
    }
  }), _vm.isLoading && _vm.tableData.length === 0 ? _c('TalpaLoaderWrapper', {
    staticClass: "loader"
  }) : _c('ExtensionTableAtom', {
    attrs: {
      "columns": _vm.columns,
      "tableData": _vm.tableData,
      "sortOption": _vm.sortOption,
      "checkboxOption": _vm.checkboxOption,
      "maxHeight": _vm.maxHeight,
      "expandOption": _vm.expandOption
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }