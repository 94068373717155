<template>
  <TableAtom>
    <VeTable
      ref="veTable"
      :columns="columns"
      :table-data.sync="tableData"
      :border-x="true"
      :border-y="true"
      :fixed-header="true"
      :row-style-option="rowStyleOption"
      :cell-style-option="cellStyleOption"
      :sort-option="sortOption"
      :expand-option="expandOption"
      :cellSelectionOption="cellSelectionOption"
      :checkbox-option="checkboxOption"
      :row-key-field-name="'rowKey'"
      :max-height="maxHeight"
      :virtual-scroll-option="virtualScrollOption"
    />
  </TableAtom>
</template>

<script>
import chroma from 'chroma-js'
import { styled } from '@egoist/vue-emotion'
import { buttonReset } from '@styles/mixins'
import { VeTable } from 'vue-easytable'

import './table-style.css'

const TableAtom = styled('div')`
  .vue-table-root {
    background: ${({ theme }) => theme.colors.atomic.tableBG};
  }
  .empty-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
    width: 100%;
    font-size: 16px;
    border-top: 0;
    border-bottom: 1px solid black;
  }

  .ve-table {
    &.ve-table-border-around {
      border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.iconBG).alpha(0.2).css()};
    }

    .ve-table-container {
      table.ve-table-content tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td.table-body-cell-actions {
        padding: 0;
        font-family: 'Open Sans', sans-serif;
      }
      .ve-table-border-x th,
      .ve-table-border-x td {
        border-bottom: 1px solid ${({ theme }) => chroma(theme.colors.atomic.iconBG).alpha(0.2).css()};
      }
      .ve-table-border-x tr:first-child > th,
      .ve-table-border-x tr.ve-table-footer-tr:first-child > td {
        border-top: 1px solid ${({ theme }) => chroma(theme.colors.atomic.iconBG).alpha(0.2).css()};
      }
      .ve-table-border-y th,
      .ve-table-border-y td {
        border-right: 1px solid ${({ theme }) => chroma(theme.colors.atomic.iconBG).alpha(0.2).css()};
      }
      .ve-table-border-y th:first-child,
      .ve-table-border-y td:first-child {
        border-left: 1px solid ${({ theme }) => chroma(theme.colors.atomic.iconBG).alpha(0.2).css()};
      }

      .ve-table-content-wrapper table.ve-table-content {
        thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th {
          font-weight: bold;
          background: ${({ theme }) => theme.colors.atomic.tableColumnHeaderBG};
          color: ${({ theme }) => theme.colors.atomic.textMain};

          .ve-table-sort .ve-table-sort-icon.active {
            color: ${({ theme }) => theme.colors.atomic.primary};
          }
        }
        tbody.ve-table-body tr.ve-table-body-tr td.ve-table-body-td,
        tbody.ve-table-body tr.ve-table-expand-tr td.ve-table-expand-td {
          background: ${({ theme }) => theme.colors.atomic.tableBG};
          color: ${({ theme }) => theme.colors.atomic.textMain};
        }
        tbody.ve-table-body.ve-table-row-hover tr.ve-table-body-tr:hover td,
        tbody.ve-table-body.ve-table-row-highlight tr.ve-table-body-tr.ve-table-tr-highlight td {
          background: ${({ theme }) => theme.colors.atomic.tableColumnHeaderBG};
        }

        tbody.ve-table-body tr.ve-table-body-row-scrolling > td {
          background: ${({ theme }) => theme.colors.atomic.tableBG};
        }
      }
    }
  }
  .actions {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 18px;
    grid-template-rows: 18px;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    button,
    a {
      ${buttonReset}
      display: inline-block;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  .ve-table .ve-table-container {
    table.ve-table-content {
      background: ${({ theme }) => theme.colors.atomic.tableBG};
      thead.ve-table-header .ve-table-header-tr .ve-table-header-th .ve-table-filter .ve-table-filter-icon {
        color: ${({ theme }) => theme.colors.white};
        &:hover {
          color: ${({ theme }) => theme.colors.pastelBlue};
        }
      }
    }
  }
  //Table Filter PopOver
  .ve-dropdown-popper {
    .ve-dropdown-operation .ve-dropdown-operation-item {
      color: ${({ theme }) => theme.colors.white};
      &:not(.ve-dropdown-filter-disable):hover {
        color: ${({ theme }) => theme.colors.white};
      }
    }
    .ve-dropdown-dd .ve-dropdown-items .ve-dropdown-items-warpper .ve-dropdown-items-multiple {
      > span {
        color: ${({ theme }) => theme.colors.white};
      }
      &:hover {
        background: ${({ theme }) => chroma(theme.colors.white).alpha(0.2).css()};
        backdrop-filter: blur(7px);
      }
    }
  }
`

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    sortOption: {
      type: Object,
      required: false,
    },
    checkboxOption: {
      type: Object,
      required: false,
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
    expandOption: {
      type: Object,
      required: false,
    },
    virtualScrollOption: {
      type: Object,
      default() {
        return {
          enable: this.tableData?.length > 500,
        }
      },
    },
  },
  components: {
    VeTable,
    TableAtom,
  },
  data() {
    return {
      rowStyleOption: {
        clickHighlight: true,
      },
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
      cellStyleOption: {
        bodyCellClass: ({ column }) => {
          if (column.field === 'actions') {
            return 'table-body-cell-actions'
          }
        },
      },
    }
  },
}
</script>
