<template>
  <TableMolecule>
    <HeaderMolecule
      :title="title"
      class="header"
      :buttonText="buttonText"
      :mode="mode"
      :to="to"
      :showBackButton="showBackButton"
      :count="tableData.length"
      :goBackRoute="goBackRoute"
      :disabledAction="disabledAction"
      :enableSearch="enableSearch"
      :searchQuery="searchQuery"
      :isLoading="isLoading"
      @action="$emit('action', $event)"
      @search="$emit('search', $event)"
    />
    <TalpaLoaderWrapper v-if="isLoading && tableData.length === 0" class="loader" />
    <ExtensionTableAtom
      :columns="columns"
      :tableData="tableData"
      :sortOption="sortOption"
      :checkboxOption="checkboxOption"
      :maxHeight="maxHeight"
      :expandOption="expandOption"
      v-else
    />
  </TableMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import ExtensionTableAtom from './ExtensionTableAtom.vue'
import HeaderMolecule from './HeaderMolecule.vue'
import { TalpaLoaderWrapper } from '@common/components'

const TableMolecule = styled('div')`
  display: grid;
  grid-template-rows: 3rem 1fr;
  grid-template-columns: 1fr;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  padding: 0.5rem;
  border-radius: 0.5rem;
  grid-gap: 0.5rem;
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 1rem;
    color: ${p => p.theme.colors.primary};

    > div,
    button {
      padding: 0;
      cursor: pointer;
      &:disabled {
        > svg {
          color: ${p => p.theme.colors.muted};
        }
        &:hover {
          background: unset;
        }
      }
      > svg {
        color: ${p => p.theme.colors.primary};
      }
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    sortOption: {
      type: Object,
      required: false,
    },
    checkboxOption: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    },
    to: {
      type: Object,
      required: false,
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    goBackRoute: {
      type: Object,
      required: false,
    },
    disabledAction: {
      type: Boolean,
      default: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    searchQuery: {
      type: String,
      required: false,
    },
    expandOption: {
      type: Object,
      required: false,
    },
  },
  components: {
    ExtensionTableAtom,
    TableMolecule,
    HeaderMolecule,
    TalpaLoaderWrapper,
  },
  data() {
    return {}
  },
}
</script>
